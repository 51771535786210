<template>
  <div class="bg-v3-green bg-opacity-20 rounded-full h-fit">
      <loading v-if="!links"></loading>
      <template v-else>
    <div class="h-full p-2">
      <nav class="flex flex-1 flex-col w-min h-full" aria-label="Sidebar">
        <ul role="list" class="space-y-1 flex flex-col justify-between h-full text-center align-center">
          <div>
              <navigation-link name="New Order" :open="panelOpen" href="#" @click="goToNewOrder"  activeClass="text-v3-white-100 bg-v3-green dark:text-v3-gray-900" additional-classes="text-v3-green">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"/></svg>
                  </template>
              </navigation-link>
            <navigation-link name="Orders" :is-active="isCurrentPage('/orders')" :open="panelOpen" :href="getLinkHref('orders')" activeClass="text-v3-white-100 bg-v3-green dark:text-v3-gray-900" additional-classes="text-v3-green">
              <template v-slot:icon>
                  <svg class="h-6 w-6 shrink-0 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2s-.9-2-2-2M1 2v2h2l3.6 7.59l-1.35 2.45c-.16.28-.25.61-.25.96c0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12l.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 20 4H5.21l-.94-2zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2s2-.9 2-2s-.9-2-2-2"/></svg>
              </template>
            </navigation-link>
              <navigation-link name="Rentals" :is-active="isCurrentPage('/rental-items')" :open="panelOpen" href="/rental-items" activeClass="text-v3-white-100 bg-v3-green dark:text-v3-gray-900" additional-classes="text-v3-green">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M4 21q-1.25 0-2.125-.875T1 18q0-.65.25-1.237T2 15.75V11h2V5h8l4.7 11.075q.15.35.225.7T17 17.5q0 1.45-1.025 2.475T13.5 21q-1.025 0-1.888-.537T10.326 19h-3.5q-.325.9-1.1 1.45T4 21m14-1V4h2v14h3v2zM4 19q.425 0 .713-.288T5 18t-.288-.712T4 17t-.712.288T3 18t.288.713T4 19m9.5 0q.625 0 1.063-.437T15 17.5t-.437-1.062T13.5 16t-1.062.438T12 17.5t.438 1.063T13.5 19m-4.575-5h4.725l-2.975-7H6v4z"/></svg>
                  </template>
              </navigation-link>
            <navigation-link name="Tender Orders" :is-active="isCurrentPage('/tender-orders')" :open="panelOpen" href="/tender-orders" activeClass="text-v3-white-100 bg-v3-green dark:text-v3-gray-900" additional-classes="text-v3-green">
              <template v-slot:icon>
                  <svg class="h-6 w-6 shrink-0 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 15 15"><path fill="currentColor" fill-rule="evenodd" d="M1 1.5A1.5 1.5 0 0 1 2.5 0h8.207L14 3.293V13.5a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 13.5zM4 4h3v1H4zm7 3H4v1h7zm0 3H8v1h3z" clip-rule="evenodd"/></svg>
              </template>
            </navigation-link>
            <navigation-link name="Take Offs" href="/take-off-orders" :open="panelOpen" :isActive="isCurrentPage('/take-off-orders')" activeClass="text-v3-white-100 bg-v3-green dark:text-v3-gray-900" additional-classes="text-v3-green">
              <template v-slot:icon>
                  <svg class="h-6 w-6 shrink-0 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M6.36 18.78L6.61 21l1.62-1.54l2.77-7.6c-.68-.17-1.28-.51-1.77-.98zm8.41-7.9c-.49.47-1.1.81-1.77.98l2.77 7.6L17.39 21l.26-2.22zM15 8c0-1.3-.84-2.4-2-2.82V3h-2v2.18C9.84 5.6 9 6.7 9 8c0 1.66 1.34 3 3 3s3-1.34 3-3m-3 1c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1"/></svg>
              </template>
            </navigation-link>
            <navigation-link name="Schedule Manager" v-if="hasLink('schedule-manager')" :open="panelOpen" href="schedule-manager" :isActive="isCurrentPage('/schedule-manager')" activeClass="text-v3-white-100 bg-v3-green dark:text-v3-gray-900" additional-classes="text-v3-green">
              <template v-slot:icon>
                  <svg class="h-6 w-6 shrink-0 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 18H4V8h16z"/></svg>
              </template>
            </navigation-link>
              <navigation-link name="Quotes Mismatch" v-if="hasLink('quotes-mismatch')" :open="panelOpen" href="quotes-mismatch" :isActive="isCurrentPage('/quotes-mismatch')" activeClass="text-v3-white-100 bg-v3-green dark:text-v3-gray-900" additional-classes="text-v3-green">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M9.01 14H2v2h7.01v3L13 15l-3.99-4zm5.98-1v-3H22V8h-7.01V5L11 9z"/></svg>
                  </template>
              </navigation-link>
            <navigation-link name="Suppliers" :open="panelOpen" v-if="hasLink('admin.companies')" :href="getLinkHref('admin.companies')" :isActive="isCurrentPage('/companies')" activeClass="text-v3-white-100 bg-v3-green dark:text-v3-gray-900" additional-classes="text-v3-green">
              <template v-slot:icon>
                  <svg class="h-6 w-6 shrink-0 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M17 2H2v15h2V4h13zm4 20l-2.5-1.68L16 22l-2.5-1.68L11 22l-2.5-1.68L6 22V6h15z"/></svg>
              </template>
            </navigation-link>
              <navigation-link name="Reports" v-if="hasLink('reports') || hasLink('client-reports') || hasLink('batch-reconcile')" :open="panelOpen" href="/procure-reports" :isActive="isCurrentPage('/procure-reports')" activeClass="text-v3-white-100 bg-v3-green dark:text-v3-gray-900" additional-classes="text-v3-green">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 8V2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V10h-6a2 2 0 0 1-2-2m0 7a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-1.5 0v-2.5A.75.75 0 0 1 12 15m-3.25-4a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-1.5 0v-6.5a.75.75 0 0 1 .75-.75m6.5 2a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75M13.5 8V2.5l6 6H14a.5.5 0 0 1-.5-.5"/></svg>
                  </template>
              </navigation-link>
              <navigation-link v-if="hasLink('admin.client')" name="Settings" :open="panelOpen" :isActive="isCurrentPage('procure-settings')" href="/procure-settings" activeClass="text-v3-white-100 bg-v3-green dark:text-v3-gray-900" additional-classes="text-v3-green">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 mx-auto" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19.14 12.94c.04-.3.06-.61.06-.94c0-.32-.02-.64-.07-.94l2.03-1.58a.49.49 0 0 0 .12-.61l-1.92-3.32a.488.488 0 0 0-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54a.484.484 0 0 0-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58a.49.49 0 0 0-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6s3.6 1.62 3.6 3.6s-1.62 3.6-3.6 3.6"/></svg>
                  </template>
              </navigation-link>
          </div>
            <div class="pt-12">
            <navigation-link name="My Dash" :open="panelOpen" href="/" activeClass="text-v3-white-100 bg-v3-green dark:text-v3-gray-900" additional-classes="text-v3-green ring-2 ring-current">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0 mx-auto" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.5 21V9L12.5 3L20.5 9V21H14.5V14H10.5V21H4.5Z" fill="currentColor"/>
                </svg>
              </template>
            </navigation-link>
          </div>
        </ul>
      </nav>
    </div>
      </template>
  </div>
</template>
<script>
import NavigationLink from "@/v3/components/navigation/NavigationLink.vue"
import Loading from "@/components/Loading.vue";

export default {
  name: 'ProcurementNavigation',
  components: {NavigationLink, Loading},
    data(){
      return {
          panelOpen: true
      };
    },
    props:{
      links: {
          type: Object,
          default: null
      }
    },
    mounted(){
      const storageSetting = window.localStorage.getItem('navigationPanelOpen');
      if(storageSetting!== null){
          this.panelOpen = storageSetting === "true";
      }
    },
    methods:{
      isCurrentPage(url)
      {
          return this.$route.path.includes(url)
      },
        togglePanel(){
          this.panelOpen = !this.panelOpen;
          window.localStorage.setItem('navigationPanelOpen', this.panelOpen);
        },
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            if(this.links) {
                return this.getFrontendRouteWithoutHostFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
            }
            return false;
        },
        goToNewOrder(){
          if(this.$route.path !== '/orders') {
              this.$router.push('/orders#new-order');
          }else{
              window.location= 'orders#new-order';
              window.location.reload();
          }
        }
    },
}
</script>
